<template>
  <div class="container-fluid">
    <hr>
    <!-- School Name -->
    <div class="row header-row">
      <div class="col text-center">
        <img class="school-logo"
             :src="schoolLogo"
             alt="school-logo"/>
        <h1 class="big school-name"> {{schoolName}} </h1>
      </div>
    </div>
    <div class="d-flex justify-content-center align-item-center"
         v-if="!school">
      <div class="big">
        Go to <router-link :to="'/profile'"> profile page </router-link>
        to set your school or <a href="#" @click="openNoSchool()">
        click here </a> to add a school.
      </div>
    </div>

    <!-- Buttons -->
    <div class="row buttons-row"
         v-if="(user.user_type === 3 || user.user_type === 4) && school">
      <div class="col-12 col-md-6 col-lg-3">
        <b-button class="btn btn-block btn-h3-blue btn-h3-medium"
                  type="button"
                  @click="openCreate">
          Create Classroom
        </b-button>
      </div>

      <div class="col-12 col-md-6 col-lg-3">
        <router-link :to="{ name: 'moderator' }">
          <b-button class="btn btn-block btn-h3-blue btn-h3-medium"
                    type="button">
            Approval Request
          </b-button>
        </router-link>
      </div>

      <div class="col-12 col-md-6 col-lg-3">
        <b-button class="btn btn-block btn-h3-blue btn-h3-medium"
                  type="button"
                  @click="openEditSchool">
          Update School Info
        </b-button>
      </div>

      <div class="col-12 col-md-6 col-lg-3">
        <router-link :to="{ name: 'influencer' }">
          <b-button class="btn btn-block btn-h3-blue btn-h3-medium"
                    type="button">
            Influencers
          </b-button>
        </router-link>
      </div>
    </div>

    <!-- Classroom List -->
    <div class="row classrooms-row">
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 classroom-col
      media-container text-center"
           v-for="(classroom, index) in classrooms"
           :key="index">
        <i class="fas fa-cog edit-icon"
           title="Edit Classroom"
           @click="openEditClassroom(classroom)"
           v-if="classroom.educator_id == user.user_id &&
             (user.user_type === 3 || user.user_type === 4)">
        </i>
        <i class="fas fa-trash-alt delete-icon"
           title="Delete Classroom"
           @click="deleteClassroom(classroom)"
           v-if="classroom.educator_id == user.user_id &&
              (user.user_type === 3 || user.user_type === 4)">
        </i>

        <router-link :to="{
                        name: 'classroom',
                        params: { classroomId: classroom.classroom_id }
                      }"
                      v-if="classroom.educator_id == user.user_id &&
                        (user.user_type === 3 || user.user_type === 4)">
          <div class="classroom"
               :style="{height: `${mediaContainerWidth}px`}">
            <img class="classroom-avatar"
                 :style="{height: `${mediaContainerWidth}px`}"
                 :src="classroom.thumbnail"
                 :title="classroom.classroom_name"
                 alt="classroom-avatar"/>
          </div>
        </router-link>

        <div class="classroom"
              :style="{height: `${mediaContainerWidth}px`}"
              @click="checkStudentClassroom(classroom)"
              v-else>
          <img class="classroom-avatar"
                :style="{height: `${mediaContainerWidth}px`}"
                :src="classroom.thumbnail"
                :title="classroom.classroom_name"
                alt="classroom-avatar"/>
        </div>

        <p class="classroom-name"
           :title="classroom.classroom_name">
          {{ classroom.classroom_name | truncate(30) }}
        </p>
      </div>
    </div>

    <!-- Load More -->
    <div class="row" v-if="classroomCurrentPage !== classroomLastPage">
      <div class="col text-center">
        <b-button class="btn btn-h3-small btn-h3-blue mb-2 mt-2"
                  type="button"
                  @click="loadMoreClassrooms">
          Load More
        </b-button>
      </div>
    </div>
    <modals-container/>

    <!-- Loading -->
    <loading :active.sync="isLoading"
              :is-full-page="true"
              background-color="#000"
              color="#068bcd"
              :opacity="0.5"
              :width="70"
              :height="70">
      <rainbow-loader></rainbow-loader>
    </loading>

  </div>
</template>

<script>
  const CreateClassroom = () =>
  import('@/components/modals/CreateClassroom.vue');
  const NoSchool = () => import('@/components/modals/NoSchool.vue');
  const EditSchool = () => import('@/components/modals/EditSchool.vue');
  const EditClassroom = () => import('@/components/modals/EditClassroom.vue');
  const Loading = () => import('vue-loading-overlay');
  const RainbowLoader = () => import('@/components/shared/RainbowLoader');

  import { mapGetters, mapActions } from 'vuex';
  import MyMediaMixin from '../../mixins/MyMediaMixin';
  import FilterMixin from '@/mixins/FilterMixin';
  import BreakpointMixin from '@/mixins/BreakpointMixin';

  export default {
    name       : 'School',
    components : {
      // eslint-disable-next-line vue/no-unused-components
      CreateClassroom,
      // eslint-disable-next-line vue/no-unused-components
      EditSchool,
      // eslint-disable-next-line vue/no-unused-components
      EditClassroom,
      // eslint-disable-next-line vue/no-unused-components
      NoSchool,
      Loading,
      RainbowLoader,
    },
    data() {
      return {
        school               : null,
        schoolLogo           : null,
        schoolName           : null,
        classrooms           : [],
        classroomPerPage     : 8,
        classroomCurrentPage : 0,
        classroomLastPage    : 0,
        pendingRequests      : [],
        isLoading            : false,
      };
    },
    mixins : [
      MyMediaMixin,
      FilterMixin,
      BreakpointMixin,
    ],
    computed : {
      ...mapGetters({
        user : 'user/user',
      }),
    },
    mounted() {
      this.isLoading = true;
      this.getUpdatedUser();
      this.getSchool().catch( () => {
        if (this.school === null) {
          this.isLoading = false;
          this.openNoSchool();
        } else {
          this.getClassrooms();
          this.getUserClassrooms();
        }
      });
    },
    watch : {
      user(value) {
        if (value.school_id) {
          this.getSchool();
          this.getClassrooms();
          this.getUserClassrooms();
        }
      },
      school(value) {
        if (value)
          this.isLoading = false;
      },
    },
    methods : {
      ...mapActions({
        'getUserClassrooms' : 'classroom/getUserClassrooms',
        'getUpdatedUser'    : 'user/getUpdatedUser',
      }),

      /**
       * Open Edit School Modal
       */
      openEditSchool() {
        this.$modal.show(EditSchool, {
          school : this.school,
        }, {
          height   : 'auto',
          width    : this.windowWidth >= 992 ? '80%' : '100%',
          maxWidth : this.windowWidth >= 992 ? 800 : 600,
          adaptive : true,
        }, {
          // eslint-disable-next-line no-unused-vars
          'before-close' : (event) => {
            this.getSchool();
          },
        });
      },

      /**
       * Open Create Classroom
       */
      openCreate() {
        this.$modal.show(CreateClassroom, {}, {
          height   : 'auto',
          adaptive : true,
        }, {
          // eslint-disable-next-line no-unused-vars
          'before-close' : (event) => {
            // TODO: Find other way to refresh classroom list -DCB
            this.getClassrooms(0);

            // const that = this;
            // setTimeout(() => {
            //   if (localStorage.createdClassroomId)
            //     that.createClassroomEmail(localStorage.createdClassroomId);
            // }, 2000);
          },
        });
      },

      /**
       * Email notification upon classroom creation
       * @param classroomId
       */
      // createClassroomEmail(classroomId) {
      //   this.$http.post('api/create_classroom_email', {
      //     'classroomId' : classroomId,
      //     'name'        : this.$store.getters['user/user'].nickname,
      //     'to'          : this.$store.getters['user/user'].email,
      //   });
      // },

      /**
       * Get all Classrooms
       * @param {integer} currentPage Set current page
       */
      getClassrooms(currentPage = this.classroomCurrentPage) {
        this.$http.get('api/school/classrooms', {
          params : {
            schoolId : this.user.school_id,
            page     : currentPage + 1,
            perPage  : this.classroomPerPage,
          },
        }).then(res => {
          this.classroomCurrentPage = res.data.currentPage;
          this.classroomLastPage = res.data.lastPage;

          if (this.classroomCurrentPage > 1) {
            const arr1 = this.classrooms;
            const arr2 = res.data.data;

            this.classrooms = arr1.concat(arr2);
          } else
            this.classrooms = res.data.data;
        }).catch(() => {
          if (currentPage === 0)
            this.classrooms = [];
        });
      },

      /**
       * Get More Classrooms
       */
      loadMoreClassrooms() {
        this.getClassrooms();
      },

      /**
       * Get School Info
       */
      getSchool() {
        return new Promise((resolve, reject) => {
          this.$http.get('api/school/with_details', {
            params : {
              id : this.user.school_id,
            },
          }).then(res => {
            this.schoolLogo = (res.data.logo_url) ? res.data.logo_url :
              process.env.VUE_APP_ADMIN_BUCKET_BASE_URL + 'school/default.png';

            this.schoolName = res.data.school_name;
            this.school = res.data;
            resolve(res.data);
          }).catch(() => {
            this.schoolLogo = process.env.VUE_APP_ADMIN_BUCKET_BASE_URL +
              'school/default.png';
            this.schoolName = 'No School';
            reject();
          });
        });
      },

      /**
       * Open No School Modal
       */
      openNoSchool() {
        this.$modal.show(NoSchool, {}, {
          height       : 'auto',
          width        : this.windowWidth >= 992 ? '80%' : '100%',
          maxWidth     : this.windowWidth >= 992 ? 800 : 600,
          adaptive     : true,
          clickToClose : false,
        }, {
          // eslint-disable-next-line no-unused-vars
          'before-close' : (event) => {
            this.isLoading = true;
          },
        });
      },

      /**
       * Open Edit Classroom Modal
       *
       * @param classroom Classroom data
       */
      openEditClassroom(classroom) {
        this.$modal.show(EditClassroom, { classroom }, {
          height   : 'auto',
          adaptive : true,
        }, {
          // eslint-disable-next-line no-unused-vars
          'before-close' : (event) => {
            this.classrooms = [];
            this.getClassrooms(0);
          },
        });
      },

      /**
       * Delete Classroom
       * @param classroom
       */
      deleteClassroom(classroom) {
        this.$modal.show('dialog', {
          title : 'Delete Classroom',
          text  : 'Are you sure you want to delete <strong>' +
            classroom.classroom_name + '</strong>?',
          buttons : [ {
            title   : 'Yes',
            handler : () => {
              this.$http.delete('/api/classroom', {
                data : {
                  'classroomId' : classroom.classroom_id,
                },
              }).then(() => {
                this.$modal.hide('dialog');
                this.getClassrooms(0);
                this.$notify({
                  group : 'notif',
                  type  : 'success',
                  title : 'Success!',
                  text  : 'Deleted ' + classroom.classroom_name +
                    ' successfully.',
                })
              }).catch(() => {
                this.$notify({
                  group : 'notif',
                  type  : 'error',
                  title : 'Failed',
                  text  : 'Oops! Something went wrong!',
                })
              });
            },
          }, {
            title : 'No',
          } ],
        });
      },

      /**
       * Check if student belongs to the classroom before redirecting to
       * classroom page
       * @param classroom
       */
      checkStudentClassroom(classroom) {
        if (this.user.user_type === 1) {
          let isJoined = false;
          const rooms = this.user.classroom;

          rooms.forEach(room => {
            if (room.classroom_id == classroom.classroom_id) {
              isJoined = true;
              this.$router.push({
                path : `classroom/${classroom.classroom_id}`,
              });
            }
          });

          if (!isJoined) {
            this.$notify({
              group : 'notif',
              type  : 'error',
              title : 'Classroom',
              text  : `Unable to view <b>${classroom.classroom_name} </b>
                classroom. <br> Join the classroom first via H3 Mobile App!`,
            });
          }
        } else if (this.user.user_type === 3 || this.user.user_type === 4) {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Classroom',
            text  : `Unable to view <b>${classroom.classroom_name} </b>
                classroom. <br> It's not yours.`,
          });
        }
      },
    },
  };

</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/user/school";
</style>
